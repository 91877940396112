$(document).on('turbolinks:load', function () {
    if (document.querySelector('#new_contact')) {
        document.querySelector('#new_contact').addEventListener('submit', function (e) {
            document.querySelector('.submit-button').disabled = false
            let valid = true

            document.querySelectorAll('.form-error').forEach(fe => {
                fe.innerText = ""
                fe.classList.add('d-none')
            })

            let inputs = document.querySelectorAll('input, textarea')
            inputs.forEach(input => {
                if (input.required) {
                    if (input.id === 'contact_photo_urls') {
                        if (document.querySelector('#contact_images').files.length !== 0) {
                            return
                        }
                    }


                    if (!checkRequired(input.value)) {
                        valid = false
                        addErrorMessage(input, `Це поле є обов'язковим.`)
                    }
                }

                if (input.value !== '') {
                    switch (input.type) {
                        case 'email':
                            if (!validateEmail(input.value)) {
                                valid = false
                                addErrorMessage(input, 'Невірно введений адрес емейл. Перевірте ще раз.')
                            }
                            break;
                    }
                }
            })
            if (!valid) {
                e.preventDefault()
            }
            return valid
        })
    }
})

function checkRequired(value) {
    if (value) {
        value = value.toString().trim()
        return value !== "";
    } else {
        return false
    }
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function addErrorMessage(input, message) {
    let errorField = input.parentElement.querySelector('.form-error')
    if (errorField) {
        if (errorField.innerText === "") {
            errorField.innerText = message
        } else {
            errorField.innerText += ` ${message}`
        }
        errorField.classList.remove('d-none')
        input.addEventListener('change', function() {
            errorField.classList.add('d-none')
            document.querySelector('.submit-button').disabled = false
        })
    }
}