// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

window.Rails = Rails

import "bootstrap"
import "./animations"
import "./validation"
import "controllers"
import AOS from 'aos';
import 'aos/dist/aos.css';

$(document).on('turbolinks:load', function () {
    AOS.init({once: true});
});
